<template>
  <div class="bc-services">
    <div class="component component__padding page">
      <div class="component__title">
        {{ $t('SERVICES.TITLE') }}
      </div>
      <div class="component__subtitle"
           v-html="$t('SERVICES.SUBTITLE')"></div>
      <div class="bc-services__content">
        <bc-accordion-big class="category"
                          v-for="category in servicesList" :key="category.id"
                          @set-active-big-accordion="setActiveCategory(category.id)"
                          :is-active="activeCategories.includes(category.id)"
                          :title="category.title">
          <bc-accordion class="category__subcategory"
                        v-for="subcategory in category.subcategories" :key="subcategory.id"
                        @set-active-accordion="setActiveSubCategory(subcategory.id)"
                        :is-active="activeSubCategories.includes(subcategory.id)"
                        :title="subcategory.title">
            <div class="category__subcategory__service"
                 v-for="service in subcategory.services" :key="service.id">
              <div class="category__subcategory__service__title">{{ service.title }}</div>
              <div class="category__subcategory__service__price">
                {{ service.price ? service.price : $t('SERVICES.FREE') }}<span v-if="service.price">&nbsp;{{ $t('INTERFACE.CURRENCY') }}</span>
              </div>
            </div>
          </bc-accordion>
        </bc-accordion-big>
      </div>
    </div>
  </div>
</template>

<script>

// import {usePageTitle} from "@/utils";
import {computed} from "vue";
import {store} from "@/store";
import {useHead} from "@vueuse/head";

export default {
  name: "bc-services",
  created() {
    useHead({
      // Can be static or computed
      title: computed(() => store.getters.getSeoDataForPage('SERVICES')?.title),
      meta: [
        {
          name: `description`,
          content: computed(() => store.getters.getSeoDataForPage('SERVICES')?.description),
        },
      ],
    })
    // usePageTitle(
    //     computed(() => store.getters.getSeoDataForPage('SERVICES')?.title),
    //     computed(() => store.getters.getSeoDataForPage('SERVICES')?.description))
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http.get('/api/services/').then(resp => {
        this.servicesList = resp.data
      }).catch(err => {
        this.error = true
        console.error(err)
      })
    },
    setActiveCategory(categoryId) {
      if (this.activeCategories.includes(categoryId)) {
        let category_index = this.activeCategories.indexOf(categoryId)

        if (category_index > -1) {
          this.activeCategories.splice(category_index, 1)

          // also need to close all its subcategories:
          let activeCategory = this.servicesList.find((x) => x.id === categoryId)
          let subcategoryIds = Array.from(activeCategory.subcategories, x => x.id)
          this.activeSubCategories = this.activeSubCategories.filter((id) => {
            return !subcategoryIds.includes(id)
          })
        }
      } else {
        this.activeCategories.push(categoryId)
      }
    },
    setActiveSubCategory(subcategoryId) {
      if (this.activeSubCategories.includes(subcategoryId)) {
        let subcategory_index = this.activeSubCategories.indexOf(subcategoryId)

        if (subcategory_index > -1) {
          this.activeSubCategories.splice(subcategory_index, 1)
        }
      } else {
        this.activeSubCategories.push(subcategoryId)
      }
    },
  },
  data() {
    return {
      servicesList: [],
      error: null,
      activeCategories: [],
      activeSubCategories: []
    }
  }
}
</script>

<style scoped lang="scss">
.bc-services {

  .component__subtitle {
    text-align: center;
    margin: 0 auto;
    max-width: 720px;
    padding: 0 20px;
  }

  &__content {
    margin-top: 100px;

    .category {

      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &__subcategory {
        position: relative;
        padding: 20px 0;

        &:not(:last-child):after {
          position: absolute;
          content: '';
          border-bottom: 1px solid #000000;
          width: 96%;
          transform: translateX(-50%);
          bottom: 0;
          left: 50%;
        }

        &__service {
          display: flex;
          align-items: center;
          justify-content: space-between;
          transition: .4s;
          padding: 13px 30px;

          &:hover {
            background-color: $black;
            color: $white;
          }
          &__price {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .bc-services__content {
    margin-top: 50px;
  }
  .bc-services__content .category__subcategory__service {
    padding: 13px 15px;
  }
  .bc-services__content .category__subcategory:not(:last-child):after {
    width: 90%;
  }
}

</style>
